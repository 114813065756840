import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col, Image } from "react-bootstrap"

import Pleasedont1 from "./../images/please don't/fp-please don't.jpg"
import Pleasedont2 from "./../images/please don't/please don't1.jpg"
import Pleasedont3 from "./../images/please don't/please don't2.jpg"

const PleaseDont = ({location, id}) => (
    <Layout>
        <Seo title="Please don't" description="Please don't, by Manon Lambeens" />
        <Row className="">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
                <h1 className="text-uppercase fs-4 text-dark">please don't</h1>
                    <p className="textAbout">The please don't series is an investigation into the shape of a letter. When do we see a letter as a readable element? And when do we view a letter as a pure form like a circle or square? The posters play with legibility and sometimes have a hidden message. 
                    </p>
        </Col>
      </Row>
      <Row className="">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Pleasedont1} fluid alt="Please don't, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Pleasedont2} fluid alt="Please don't Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Pleasedont3} fluid alt="Please don't, Manon Lambeens"/>
        </Col>
      </Row>
    </Layout>
)

export default PleaseDont;